<template>
  <div class="consultation" @click="toContact">
    <div class="left">
    <icon name="consultation-mail-icon" class="mail-icon-cls"/>
    <span class="consultation-text">お問い合わせ</span>
    </div>
    <icon name="consultation-arrow-icon" class="arrow-icon-cls"/>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    linkType: String
  },
  setup(props) {
    console.log(props)
    const router = useRouter();

    // method
    const toContact = () => {
      props.linkType === 'corporation' ? router.push('/inquiry/business/input') : router.push('/inquiry/input')
    }

    return {
      // method
      toContact,
    };
  }
});
</script>

<style lang='scss'>
.consultation {
  height: 80px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    backdrop-filter: blur(10px);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: row;
    .mail-icon-cls {
      width: 22px;
      height: 18px;
      margin-left: 30px;
    }
    .consultation-text {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $primary;
    }
  }
  .arrow-icon-cls {
    width: 42px;
    height: 12px;
    margin-right: 35px;
  }
  @include mq(sm) {
    height: 60px;
    .left {
      .mail-icon-cls {
        width: 20px;
        height: 16px;
        margin-left: 22px;
      }
      .consultation-text {
        margin-left: 12px;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $primary;
      }
    }
    .arrow-icon-cls {
      margin-right: 20px;
    }
  }
}
</style>