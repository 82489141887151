<template>
  <div class="apply">
    <div class="apply-title-container">
      <span class="apply-title">新規会員登録</span>
      <div class="apply-title-option">
        <div
          class="option-personal"
          :class="{ 'op-select': viewState.selectOption == 'personal' }"
          @click="viewState.selectOption = 'personal'"
        >
          <span class="personal-top-text"
          :class="{ 'op-select-font-color': viewState.selectOption == 'personal' }">Personal</span>
          <span class="poersonal-bottom-text">個人</span>
        </div>
        <div class="divider" />
        <div
          class="option-business"
          :class="{ 'op-select': viewState.selectOption == 'corporate' }"
          @click="viewState.selectOption = 'corporate'"
        >
          <span class="business-top-text"
          :class="{ 'op-select-font-color': viewState.selectOption == 'corporate' }">Corporate</span>
          <span class="business-bottom-text">法人</span>
        </div>
      </div>
    </div>
    <personal v-if="viewState.selectOption == 'personal'" />
    <corporate v-if="viewState.selectOption == 'corporate'" />
  </div>
</template>

<script>
import personal from "./apply-personal.vue";
import corporate from "./apply-corporate.vue";
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    personal,
    corporate,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const viewState = reactive({
      selectOption: "personal",
    });
    const isLogin = computed(() => store.state.user.isLogin);
    
    window.scrollTo(0, 0);

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      viewState,
    };
  },
});
</script>

<style lang="scss" scoped>
.apply {
  position: relative;
  max-width: 1366px;
  margin: 0 auto;
  .apply-title-container {
    z-index: 1;
    position: absolute;
    top: 60px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 115px;
    padding-right: 115px;
    align-items: center;
    justify-content: space-between;
    .apply-title {
      width: 433px;
      height: 70px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 70px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: $black;
    }
    .apply-title-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      &::before {
        z-index: -1;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(100px);
      }
      .op-select {
        background: $white;
        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          width: 100%;
          height: 0px;
          border-bottom: 4px solid $black;
        }
      }
      .option-personal {
        cursor: pointer;
        position: relative;
        width: 214px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .personal-top-text {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $dark-grey;
        }
        .op-select-font-color {
          color: $black;
        }
        .poersonal-bottom-text {
          margin-top: 13.5px;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.08em;
          color: $black;
          opacity: 0.5;
        }
      }
      .divider {
        width: 0px;
        height: 20px;
        border-right: 1px solid $black;
      }
      .option-business {
        cursor: pointer;
        position: relative;
        width: 214px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .business-top-text {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $dark-grey;
        }
        .op-select-font-color {
          color: $black;
        }
        .business-bottom-text {
          margin-top: 13.5px;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.08em;
          color: $black;
          opacity: 0.5;
        }
      }
    }
  }
  @include mq(sm) {
    position: relative;
    .apply-title-container {
      z-index: 1;
      position: absolute;
      top: 80px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 50px;
      padding-right: 50px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      .apply-title {
        width: 275px;
        height: 30px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 30px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
      }
      .apply-title-option {
        margin-top: 71px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: rgba(255, 255, 255, 0.3);
        position: relative;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(100px);
        }
        .op-select {
          background: $white;
          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            width: 100%;
            height: 0px;
            border-bottom: 4px solid $black;
          }
        }
        .option-personal {
          cursor: pointer;
          position: relative;
          width: 137px;
          height: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .personal-top-text {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: $dark-grey;
          }
          .op-select-font-color {
            color: $black;
          }
          .poersonal-bottom-text {
            margin-top: 5px;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.08em;
            color: $black;
            opacity: 0.5;
          }
        }
        .divider {
          width: 0px;
          height: 20px;
          border-right: 1px solid $black;
        }
        .option-business {
          cursor: pointer;
          position: relative;
          width: 137px;
          height: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .business-top-text {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: $dark-grey;
          }
          .op-select-font-color {
            color: $black;
          }
          .business-bottom-text {
            margin-top: 5px;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.08em;
            color: $black;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>