<template>
  <div class="corporate">
    <div class="corporate-header-bg-container">
      <icon name="apply-corporate-header-bg2" class="pc-bg2" />
      <icon name="apply-corporate-header-sp-bg2" class="sp-bg2" />
    </div>
    <div class="corporate-body">
      <div class="business-container" v-if="false">
        <div class="business">
          <div class="business-title-container">
            <span class="business-title">BUSINESS</span>
            <span class="business-subtitle">ビジネス</span>
          </div>
          <div class="business-summary-container">
            <span class="summary1">全ての動画を視聴可能</span>
            <div class="divider" />
            <div class="icon-summary2">
              <icon name="apply-fiveperson-icon" class="fiveperson-icon" />
              <span class="summary2">5ユーザーライセンス</span>
            </div>
          </div>
          <div class="business-buttons">
            <div class="business-price-container">
              <span class="business-price">〜¥24,500</span>
              <span class="business-price-unit">/月</span>
            </div>
            <div class="business-buy-container">
              <span class="business-buy-text">購入する</span>
              <icon name="chapter-detail-btn" />
            </div>
          </div>
        </div>
        <icon name="bg-dot-circle" class="business-bottom-bg" />
      </div>
      <div class="enterprise-container">
        <div class="enterprise">
          <div class="enterprise-title-container">
            <span class="enterprise-title">ENTERPRISE</span>
            <span class="enterprise-subtitle">エンタープライズ</span>
          </div>
          <div class="enterprise-summary-container">
            <span class="summary1">全ての動画を視聴可能</span>
            <div class="divider" />
            <div class="icon-summary2">
              <icon name="apply-fivemoreperson-icon" class="fivemoreperson-icon" />
              <span class="summary2"><span>5つ以上の</span><span>ユーザーライセンス</span></span>
            </div>
          </div>
          <div class="enterprise-buttons">
            <div class="enterprise-price-container">
              <span class="enterprise-price">お問い合わせください</span>
            </div>
            <div class="enterprise-consultation-container" @click="$router.push('/inquiry/business/input')">
              <span class="enterprise-consultation-text">お問い合わせ</span>
              <icon name="chapter-detail-btn" />
            </div>
          </div>
        </div>
        <icon name="bg-dot-circle" class="business-bottom-bg" />
      </div>
      <breakdown />
      <consultationBtn class="consultationBtn" linkType="corporation" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import breakdown from "@/components/package-breakdown.vue";
import consultationBtn from "./consultation-btn.vue";

export default defineComponent({
  components: {
    breakdown,
    consultationBtn,
  },
});
</script>

<style lang="scss" scoped>
.corporate {
  max-width: 1366px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -292px;
    right: -292px;
    width: 584px;
    height: 584px;
    background: $grey;
    border-radius: 50%;
    z-index: -1;
  }
  .corporate-header-bg-container {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/img/apply-corporate-header-bg1.png);
    position: relative;
    overflow: hidden;
    .pc-bg2 {
      width: 1366px;
      height: 402px;
      position: absolute;
      left: 0;
      top: -40.5px;
    }
    .sp-bg2 {
      display: none;
    }
  }
  .corporate-body {
    margin-top: -160px;
    max-width: 1366px;
    height: fit-content;
    .business-container {
      position: relative;
      .business {
        max-width: 1266px;
        padding-bottom: 78px;
        margin-left: 50px;
        margin-right: 50px;
        position: relative;
        z-index: 1;
        &::before {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.95);
          box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
          backdrop-filter: blur(10px);
        }
        .business-title-container {
          display: flex;
          max-width: 1136px;
          height: 160px;
          border-bottom: 0.5px solid $black-50;
          flex-direction: row;
          align-items: center;
          margin-left: 65px;
          margin-right: 65px;
          .business-title {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
          }
          .business-subtitle {
            margin-left: 40px;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $black;
            opacity: 0.5;
          }
        }
        .business-summary-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          .summary1 {
            margin-left: 115px;
            height: 100px;
            width: 226px;
            white-space: nowrap;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            color: $black;
          }
          .divider {
            width: 0px;
            height: 20px;
            margin-left: 102px;
            border-right: 1px solid $black;
          }
          .icon-summary2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 102px;
            .fiveperson-icon {
              width: 71px;
              height: 31px;
            }
            .summary2 {
              height: 100px;
              white-space: nowrap;
              margin-left: 40px;
              font-weight: 500;
              font-size: 16px;
              line-height: 45px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1em;
              color: $black;
            }
          }
        }
        .business-buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 1136px;
          margin-left: 65px;
          margin-right: 65px;
          border-bottom: 4px solid $secondary;
          .business-price-container {
            width: 757px;
            height: 80px;
            display: flex;
            align-items: center;
            background: rgba(240, 243, 248, 0.5);
            .business-price {
              margin-left: 50px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 40px;
              line-height: 60px;
              letter-spacing: 0.05em;
              color: $secondary;
            }
            .business-price-unit {
              margin-left: 10px;
              font-weight: 700;
              font-size: 18px;
              line-height: 60px;
              letter-spacing: 0.05em;
              color: $dark-grey;
            }
          }
          .business-buy-container {
            width: 379px;
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: $black;
            cursor: pointer;
            .business-buy-text {
              margin-left: 30px;
              font-weight: 700;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $white;
            }
            svg {
              width: 40px;
              height: 10px;
              margin-right: 20px;
            }
          }
        }
      }
      .business-bottom-bg {
        width: 620px;
        height: 620px;
        position: absolute;
        left: -310px;
        bottom: -310px;
        z-index: -1;
      }
    }
    .enterprise-container {
      position: relative;
      .enterprise {
        max-width: 1266px;
        padding-bottom: 80px;
        margin-top: 30px;
        margin-left: 50px;
        margin-right: 50px;
        position: relative;
        z-index: 1;
        &::before {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.95);
          box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
          backdrop-filter: blur(10px);
        }
        .enterprise-title-container {
          display: flex;
          max-width: 1136px;
          height: 160px;
          border-bottom: 0.5px solid $black-50;
          flex-direction: row;
          align-items: center;
          margin-left: 65px;
          margin-right: 65px;
          .enterprise-title {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
          }
          .enterprise-subtitle {
            margin-left: 40px;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $black;
            opacity: 0.5;
          }
        }
        .enterprise-summary-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          .summary1 {
            margin-left: 115px;
            height: 100px;
            width: 226px;
            white-space: nowrap;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            color: $black;
          }
          .divider {
            width: 0px;
            height: 20px;
            margin-left: 102px;
            border-right: 1px solid $black;
          }
          .icon-summary2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 102px;
            .fivemoreperson-icon {
              width: 84px;
              height: 31px;
            }
            .summary2 {
              height: 100px;
              white-space: nowrap;
              margin-left: 40px;
              font-weight: 500;
              font-size: 16px;
              line-height: 45px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1em;
              color: $black;
            }
          }
        }
        .enterprise-buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 1136px;
          margin-left: 65px;
          margin-right: 65px;
          border-bottom: 4px solid #02daa6;
          .enterprise-price-container {
            width: 757px;
            height: 80px;
            display: flex;
            align-items: center;
            background: rgba(240, 243, 248, 0.5);
            position: relative;
            .enterprise-price {
              margin-left: 50px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 60px;
              display: flex;
              align-items: center;
              letter-spacing: 0.05em;
              color: $dark-grey;
            }
          }
          .enterprise-consultation-container {
            width: 379px;
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: $primary;
            position: relative;
            cursor: pointer;
            .enterprise-consultation-text {
              margin-left: 30px;
              font-weight: 700;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $white;
            }
            svg {
              width: 40px;
              height: 10px;
              margin-right: 20px;
            }
          }
        }
      }
      .business-bottom-bg {
        width: 620px;
        height: 620px;
        position: absolute;
        left: -310px;
        bottom: -310px;
        z-index: -1;
      }
    }
    .consultationBtn {
      margin: 100px 115px 115px;
    }
  }
  @include mq(sm) {
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -127px;
      right: -127px;
      width: 255px;
      height: 255px;
      background: $grey;
      border-radius: 50%;
      z-index: -1;
    }
    .corporate-header-bg-container {
      width: 100%;
      height: 429px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../assets/img/apply-corporate-header-sp-bg1.png);
      position: absolute;
      left: 0;
      top: 0;
      .pc-bg2 {
        display: none;
      }
      .sp-bg2 {
        display: block;
        width: 326px;
        height: 362px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
    .corporate-body {
      margin-top: 300px;
      .business-container {
        position: relative;
        .business {
          padding-bottom: 60px;
          margin-left: 20px;
          margin-right: 20px;
          position: relative;
          z-index: 1;
          &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
            backdrop-filter: blur(10px);
          }
          .business-title-container {
            display: flex;
            height: 128px;
            border-bottom: 0.5px solid $black-50;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: center;
            margin-left: 30px;
            margin-right: 30px;
            .business-title {
              margin-top: 10px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 30px;
              line-height: 40px;
              letter-spacing: 0.08em;
              color: $black;
            }
            .business-subtitle {
              margin-left: 0px;
              font-weight: 500;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
            }
          }
          .business-summary-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: unset;
            .summary1 {
              margin-left: 30px;
              height: 78px;
              white-space: nowrap;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 18px;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $black;
            }
            .divider {
              width: 20px;
              height: 0px;
              margin-left: 30px;
              border-bottom: 1px solid $black;
            }
            .icon-summary2 {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 30px;
              margin-top: 30px;
              height: 33px;
              .fiveperson-icon {
                width: 71px;
                height: 31px;
              }
              .summary2 {
                height: unset;
                white-space: nowrap;
                margin-left: 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.1em;
                color: $black;
              }
            }
          }
          .business-buttons {
            display: block;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
            border-bottom: none;
            border-left: 4px solid $secondary;
            .business-price-container {
              width: auto;
              height: 60px;
              display: flex;
              align-items: center;
              background: rgba(240, 243, 248, 0.5);
              .business-price {
                margin-left: 30px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                font-size: 30px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: $secondary;
              }
              .business-price-unit {
                margin-left: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.05em;
                color: $dark-grey;
              }
            }
            .business-buy-container {
              width: auto;
              height: 60px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              background: $black;
              .business-buy-text {
                margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $white;
              }
              svg {
                width: 40px;
                height: 10px;
                margin-right: 20px;
              }
            }
          }
        }
        .business-bottom-bg {
          width: 283px;
          height: 283px;
          position: absolute;
          left: -141.5px;
          bottom: -141.5px;
          z-index: -1;
        }
      }
      .enterprise-container {
        width: auto;
        .enterprise {
          padding-bottom: 60px;
          margin-left: 20px;
          margin-right: 20px;
          position: relative;
          z-index: 1;
          &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
            backdrop-filter: blur(10px);
          }
          .enterprise-title-container {
            display: flex;
            height: 128px;
            border-bottom: 0.5px solid $black-50;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: center;
            margin-left: 30px;
            margin-right: 30px;
            .enterprise-title {
              margin-top: 10px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 30px;
              line-height: 40px;
              letter-spacing: 0.08em;
              color: $black;
            }
            .enterprise-subtitle {
              margin-left: 0px;
              font-weight: 500;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
            }
          }
          .enterprise-summary-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: unset;
            .summary1 {
              margin-left: 30px;
              height: 78px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 18px;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $black;
            }
            .divider {
              width: 20px;
              height: 0px;
              margin-left: 30px;
              border-bottom: 1px solid $black;
            }
            .icon-summary2 {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 30px;
              margin-top: 30px;
              height: 33px;
              .fivemoreperson-icon {
              }
              .summary2 {
                width: 181px;
                height: 28px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.1em;
                color: $black;
              }
            }
          }
          .enterprise-buttons {
            display: block;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
            border-bottom: 0;
            border-left: 4px solid #02daa6;
            .enterprise-price-container {
              width: 100%;
              height: 60px;
              display: flex;
              align-items: center;
              background: rgba(240, 243, 248, 0.5);
              .enterprise-price {
                margin-left: 30px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.05em;
                color: $dark-grey;
              }
            }
            .enterprise-consultation-container {
              width: 100%;
              height: 60px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              background: $primary;
              position: relative;
              .enterprise-consultation-text {
                margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $white;
              }
              svg {
                width: 40px;
                height: 10px;
                margin-right: 20px;
              }
            }
          }
        }
        .business-bottom-bg {
          width: 283px;
          height: 283px;
          position: absolute;
          left: -141.5px;
          bottom: -141.5px;
          z-index: -1;
        }
      }
      .consultationBtn {
        margin: 60px 50px 80px;
      }
    }
  }
}
</style>