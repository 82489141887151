<template>
  <div class="personal">
    <div class="personal-header-bg-container">
      <icon name="apply-personal-header-bg2" class="pc-bg2" />
      <icon name="apply-personal-header-sp-bg2" class="sp-bg2" />
    </div>
    <div class="personal-body">
      <div class="premium-container">
        <div class="premium">
          <div class="premium-title-container">
            <span class="premium-title">PREMIUM</span>
            <span class="premium-subtitle">プレミアム</span>
            <span class="premium-free-term">1週間無料</span>
          </div>
          <div class="premium-title-container-sp">
            <div class="premium-subtitle">プレミアム</div>
            <div class="premium-title-row">
              <span class="premium-title">PREMIUM</span>
              <span class="premium-free-term">1週間無料</span>
            </div>
          </div>
          <div class="premium-summary-container">
            <span class="summary1">全ての動画を視聴可能</span>
            <div class="divider" />
            <div class="icon-summary2">
              <icon name="person-icon" class="oneperson-icon" />
              <span class="summary2">1ユーザーライセンス</span>
            </div>
          </div>
          <div class="premium-buttons">
            <div class="premium-price-container">
              <span class="premium-price">¥3,300</span>
              <span class="premium-price-unit">/月（税込）</span>
            </div>
            <div class="premium-buy-container" @click="toBuy">
              <span class="premium-buy-text">購入する</span>
              <icon name="chapter-detail-btn" />
            </div>
          </div>
        </div>
        <icon name="bg-dot-circle" class="premium-bottom-bg" />
      </div>
      <commonTitle title="PLAN DETAILS" subTitle="機能内訳" />
      <breakdown />
      <consultationBtn class="consultationBtn" />
    </div>
  </div>
</template>

<script>
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import breakdown from "@/components/package-breakdown.vue";
import consultationBtn from "./consultation-btn.vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    commonTitle,
    breakdown,
    consultationBtn,
  },
  setup() {
    const router = useRouter();

    // method
    const toBuy = () => {
      router.push("/apply/send");
    };

    return {
      // method
      toBuy,
    };
  },
});
</script>

<style lang="scss" scoped>
.personal {
  max-width: 1366px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -292px;
    right: -292px;
    width: 584px;
    height: 584px;
    background: $grey;
    border-radius: 50%;
    z-index: -1;
  }
  .personal-header-bg-container {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/img/apply-personal-header-bg1.png);
    .pc-bg2 {
      width: 1252px;
      height: 261px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .sp-bg2 {
      display: none;
    }
  }
  .personal-body {
    margin-top: -160px;
    max-width: 1366px;
    height: fit-content;
    .premium-container {
      position: relative;
      .premium {
        max-width: 1266px;
        padding-bottom: 78px;
        margin-left: 50px;
        margin-right: 50px;
        position: relative;
        z-index: 1;
        &::before {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.95);
          box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
          backdrop-filter: blur(10px);
        }
        .premium-title-container {
          display: flex;
          max-width: 1136px;
          height: 160px;
          border-bottom: 0.5px solid $black-50;
          flex-direction: row;
          align-items: center;
          margin-left: 65px;
          margin-right: 65px;
          .premium-title {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
          }
          .premium-subtitle {
            margin-left: 40px;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $black;
            opacity: 0.5;
          }
          .premium-free-term {
            margin-left: 40px;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: #02daa6;
          }
        }
        .premium-title-container-sp {
          display: none;
        }
        .premium-summary-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          .summary1 {
            margin-left: 115px;
            width: 226px;
            height: 100px;
            white-space: nowrap;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            color: $black;
          }
          .divider {
            width: 0px;
            height: 20px;
            margin-left: 102px;
            border-right: 1px solid $black;
          }
          .icon-summary2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 102px;
            .oneperson-icon {
              width: 19px;
              height: 26px;
            }
            .summary2 {
              height: 100px;
              white-space: nowrap;
              margin-left: 40px;
              font-weight: 500;
              font-size: 16px;
              line-height: 45px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1em;
              color: $black;
            }
          }
        }
        .premium-buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 1136px;
          margin-left: 65px;
          margin-right: 65px;
          border-bottom: 4px solid $secondary;
          .premium-price-container {
            width: 757px;
            height: 80px;
            display: flex;
            align-items: center;
            background: rgba(240, 243, 248, 0.5);
            .premium-price {
              margin-left: 50px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 40px;
              line-height: 60px;
              letter-spacing: 0.05em;
              color: $secondary;
            }
            .premium-price-unit {
              margin-left: 10px;
              font-weight: 700;
              font-size: 18px;
              line-height: 60px;
              letter-spacing: 0.05em;
              color: $dark-grey;
            }
          }
          .premium-free-container {
            width: 379px;
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: $primary;
            cursor: pointer;
            .premium-free-text {
              margin-left: 30px;
              font-weight: 700;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $white;
            }
            svg {
              width: 40px;
              height: 10px;
              margin-right: 20px;
            }
          }
          .premium-buy-container {
            width: 379px;
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: $black;
            cursor: pointer;
            .premium-buy-text {
              margin-left: 30px;
              font-weight: 700;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $white;
            }
            svg {
              width: 40px;
              height: 10px;
              margin-right: 20px;
            }
          }
        }
      }
      .premium-bottom-bg {
        width: 620px;
        height: 620px;
        position: absolute;
        left: -310px;
        bottom: -310px;
        z-index: -1;
      }
    }
    .common-title {
      margin-left: 50px;
      margin-top: 68.5px;
      &::before {
        top: 4px;
      }
      ::v-deep(.common-title-area) {
        height: 70px;
        .common-titles-title {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 40px;
          line-height: 60px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $black;
        }
        .common-titles-sub-title {
          font-size: 20px;
          line-height: 70px;
          letter-spacing: 0.08em;
          color: $black-50;
        }
      }
    }
    @include mq(sm) {
      .common-title {
        margin-left: 20px;
        margin-top: 60px;
        &::before {
          top: 32px;
        }
        ::v-deep(.common-title-area) {
          justify-content: flex-end;
          height: unset;
          .common-titles-title {
            width: 275px;
            height: 40px;
            text-align: left;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 0.08em;
            color: $black;
          }
          .common-titles-sub-title {
            width: 80px;
            height: 18px;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: $black-50;
          }
        }
      }
    }
    .consultationBtn {
      margin: 100px 115px 115px;
    }
  }
  @include mq(sm) {
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -127px;
      right: -127px;
      width: 255px;
      height: 255px;
      background: $grey;
      border-radius: 50%;
      z-index: -1;
    }
    .personal-header-bg-container {
      width: 100%;
      height: 429px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../assets/img/apply-personal-header-sp-bg1.png);
      position: absolute;
      left: 0;
      top: 0;
      .pc-bg2 {
        display: none;
      }
      .sp-bg2 {
        display: block;
        width: 326px;
        height: 364px;
        position: absolute;
        left: 0px;
        top: -2px;
      }
    }
    .personal-body {
      margin-top: 300px;
      .premium-container {
        position: relative;
        .premium {
          padding-bottom: 60px;
          margin-left: 20px;
          margin-right: 20px;
          position: relative;
          z-index: 1;
          &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
            backdrop-filter: blur(10px);
          }
          .premium-title-container {
            display: none;
          }
          .premium-title-container-sp {
            display: block;
            padding-top: 30px;
            height: 128px;
            border-bottom: 0.5px solid $black-50;
            margin-left: 30px;
            margin-right: 30px;

            .premium-subtitle {
              margin-left: 0px;
              font-weight: 500;
              font-size: 18px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
            }

            .premium-title-row {
              display: flex;
              flex-direction: row;

              .premium-title {
                margin-top: 10px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
                letter-spacing: 0.08em;
                color: $black;
              }
              .premium-free-term {
                margin-top: 10px;
                margin-left: 20px;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: #02daa6;
              }
            }
          }
          .premium-summary-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: unset;
            .summary1 {
              margin-left: 30px;
              height: 78px;
              white-space: nowrap;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 30px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1em;
              color: $black;
            }
            .divider {
              width: 20px;
              height: 0px;
              margin-left: 30px;
              border-bottom: 1px solid $black;
            }
            .icon-summary2 {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 30px;
              margin-top: 30px;
              height: 33px;
              .oneperson-icon {
                width: 19px;
                height: 26px;
              }
              .summary2 {
                height: unset;
                white-space: nowrap;
                margin-left: 18px;
                font-weight: 500;
                font-size: 16px;
                line-height: 45px;
                display: flex;
                align-items: center;
                letter-spacing: 0.1em;
                color: $black;
              }
            }
          }
          .premium-buttons {
            display: block;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
            border-bottom: none;
            border-left: 4px solid $secondary;
            .premium-price-container {
              width: auto;
              height: 60px;
              display: flex;
              align-items: center;
              background: rgba(240, 243, 248, 0.5);
              .premium-price {
                margin-left: 30px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                font-size: 30px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: $secondary;
              }
              .premium-price-unit {
                margin-left: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.05em;
                color: $dark-grey;
              }
            }
            .premium-free-container {
              width: auto;
              height: 60px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              background: $primary;
              .premium-free-text {
                margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $white;
              }
              svg {
                width: 40px;
                height: 10px;
                margin-right: 20px;
              }
            }
            .premium-buy-container {
              width: auto;
              height: 60px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              background: $black;
              .premium-buy-text {
                margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $white;
              }
              svg {
                width: 40px;
                height: 10px;
                margin-right: 20px;
              }
            }
          }
        }
        .premium-bottom-bg {
          width: 283px;
          height: 283px;
          position: absolute;
          left: -141.5px;
          bottom: -141.5px;
          z-index: -1;
        }
      }
      .consultationBtn {
        margin: 60px 50px 80px;
      }
    }
  }
}
</style>